import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("generic-table-simple-select")
@Component({
    selector: "generic-table-simple-select",
    template: `
        <select [(ngModel)]="modelValue" (ngModelChange)="modelValueChange.emit(modelValue)" class="cn-form__control">
            @for (option of options; track $index) {
                <option [value]="option.value">{{ option.label }}</option>
            }
        </select>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericTableSimpleSelectComponent {
    @Input() modelValue: string;
    @Input() options: { value: string; label: string }[];
    @Output() modelValueChange = new EventEmitter<string>();
}
