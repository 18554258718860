import { NgModule } from "@angular/core";
import { S25TableCellComponent } from "./s25.table.cell.component";
import { CommonModule } from "@angular/common";
import { S25TableComponent } from "./s25.table.component";
import { FormsModule } from "@angular/forms";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25DndSortableModule } from "../s25-dnd-sortable/s25.dnd.sortable.module";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25InfiniteScrollModule } from "../s25-infinite-scroll/s25.infinite.scroll.module";
import { GenericTableButtonComponent } from "./generics/generic.table.button.component";
import { GenericTableListComponent } from "./generics/generic.table.list.component";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25ShiftSelectableModule } from "../s25-shift-select/s25.shift.selectable.module";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import { TableEditableDateComponent } from "./generics/table-editable-date.component";
import { S25DateModule } from "../s25-dateformat/s25.date.module";
import { GenericTableFadePreviewComponent } from "./generics/generic.table.fade.preview.component";
import { GenericTableRenderHTMLComponent } from "./generics/generic.table.render.html.component";
import { GenericTableMultiselectSearchCriteriaComponent } from "./generics/generic.table.multiselect.search.criteria.component";
import { S25SortIndicatorComponent } from "../../standalone/s25.sort.indicator.component";
import { S25ButtonComponent } from "../../standalone/s25.button.component";
import { GenericTableSimpleSelectComponent } from "./generics/generic.table.simple.select.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        S25LoadingInlineModule,
        S25DndSortableModule,
        S25EditableModule,
        S25MultiselectModule,
        S25InfiniteScrollModule,
        S25CheckboxModule,
        S25ShiftSelectableModule,
        S25IconModule,
        S25DateModule,
        S25SortIndicatorComponent,
        S25ButtonComponent,
    ],
    exports: [
        S25TableComponent,
        GenericTableButtonComponent,
        GenericTableListComponent,
        GenericTableRenderHTMLComponent,
        TableEditableDateComponent,
        GenericTableFadePreviewComponent,
        GenericTableMultiselectSearchCriteriaComponent,
        GenericTableSimpleSelectComponent,
    ],
    declarations: [
        S25TableComponent,
        S25TableCellComponent,
        GenericTableButtonComponent,
        GenericTableListComponent,
        GenericTableRenderHTMLComponent,
        TableEditableDateComponent,
        GenericTableFadePreviewComponent,
        GenericTableMultiselectSearchCriteriaComponent,
        GenericTableSimpleSelectComponent,
    ],
    providers: [
        S25TableComponent,
        S25TableCellComponent,
        GenericTableButtonComponent,
        GenericTableListComponent,
        GenericTableRenderHTMLComponent,
        TableEditableDateComponent,
        GenericTableFadePreviewComponent,
        GenericTableMultiselectSearchCriteriaComponent,
        GenericTableSimpleSelectComponent,
    ],
})
export class S25TableModule {}
